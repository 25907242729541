import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Race() {
    return (
        <div className="text-center container">
            <Header />

            <div style={{marginTop: '40vh'}}>
                <div>
                    <img src='/img/text_coming_soon.png' alt="GIF Description"
                         style={{width: '300px', height: 'auto', maxWidth: '50%'}}/>
                </div>
                <div className="mt-5">
                    <img src='/img/icon_homepage.png' alt="GIF Description" style={{width: '50px', height: 'auto'}}/>
                    <div className="text-primary"><Link to="/">Go To Homepage</Link></div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Race;
  