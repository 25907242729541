import React from "react";
import { Carousel } from "react-bootstrap";

const PhotoCarousel = ({ images }) => {
    return (
        <Carousel>
            {images.map((image, index) => (
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-100"
                        src={image.src}
                        alt={`Slide ${index}`}
                        style={{ height: "", objectFit: "cover" }}
                    />
                    <Carousel.Caption>
                        <h5>{image.caption}</h5>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default PhotoCarousel;
