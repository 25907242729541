import ArticleLayout from "../../layouts/ArticleLayout";

function Construct() {

    return (
        <div className="container">
            <ArticleLayout>
                <h1 className="text-center pb-4">Project Real World (The Construct)</h1>
                <p><strong>Project Real World</strong>, also known as <strong>The Construct</strong>, is an MMORPG
                    platform that theoretically supports an <strong>infinite map size</strong>. The core aim of this
                    project is to run and scale the entire platform using only <strong>JavaScript</strong>.</p>

                <h2>Client-Side Structure</h2>
                <p>The client-side system is built entirely with plain JavaScript and is divided into two primary
                    components:</p>
                <ul>
                    <li><strong>ThreeJS (WebGL):</strong> Handles a canvas for 3D animations, managing the "RPG" aspect
                        of the game.
                    </li>
                    <li><strong>JavaScript Worker (Threads):</strong> Manages socket connections, handling the "MMO"
                        side of the platform.
                    </li>
                </ul>

                <h2>Server-Side Structure</h2>
                <p>On the server-side, two primary components work together:</p>
                <ul>
                    <li>A <strong>Socket server</strong> for managing player connections and online services.</li>
                    <li>A <strong>Web server</strong> for storing and serving terrain objects and managing user
                        accounts.
                    </li>
                </ul>

                <h2>Why ThreeJS?</h2>
                <p><strong>ThreeJS</strong> is a modern JavaScript-based OpenGL library. It can be easily imported into
                    the project via <strong>NPM</strong> and works seamlessly with JavaScript. It
                    uses <strong>WebGL</strong> (essentially OpenGL in a browser) to perform 2D and 3D rendering.
                </p>

                <h2>Why Workers?</h2>
                <p>Using ThreeJS and Socket.io together requires separate threads, known as <strong>workers</strong> in
                    JavaScript. Without workers, these two resource-intensive processes would block each other:</p>
                <ul>
                    <li><strong>ThreeJS:</strong> Updates the screen’s rendering <strong>60 times per second</strong>.
                    </li>
                    <li><strong>Socket.io:</strong> Maintains a <strong>continuous connection</strong> for online
                        interactions.
                    </li>
                </ul>
                <p>By using workers, these processes operate independently and efficiently.</p>

                <h2>How Does the "Infinite Map" Work?</h2>
                <p>Theoretically, <strong>The Construct</strong> map is infinitely large. However, it actually consists
                    of only <strong>9 main areas</strong>. These areas and the objects within them are dynamically
                    updated based on player movements, creating the illusion of an infinite world.</p>
                <p>The following diagrams explain how this system functions:</p>

                <h3>Diagram 1: How Online Scaling Works (Socket Scaling)</h3>
                <div className="text-center my-5">
                    <a href="/img/construct/connection.png" target="_blank">
                        <img src='/img/construct/connection.png' alt="GIF Description"
                             style={{width: '80%', height: 'auto'}}/>
                    </a>
                </div>
                <p>Each square in the diagram corresponds to one <strong>Area</strong>. From a top-down perspective, the
                    camera captures only a small portion of these areas. For demonstration purposes, a hypothetical
                    distant view of the grid is shown:</p>
                <ul>
                    <li>Each square opens a separate channel on a dedicated socket server.</li>
                    <li>A player connects to <strong>9 channels</strong> at a time: the one they are currently in and
                        the surrounding 8. This allows them to receive data about nearby players’ movements and public
                        messages.
                    </li>
                    <li>ThreeJS only renders and considers the positions of players in the central area. Movements in
                        other areas are ignored for rendering, optimizing performance for both client and server.
                    </li>
                </ul>

                <h3>Diagram 2: How the Infinite Map Works (ThreeJS Scaling)</h3>
                <div className="text-center my-5">
                    <a href="/img/construct/movement.png" target="_blank">
                        <img src='/img/construct/movement.png' alt="GIF Description"
                             style={{width: '60%', height: 'auto'}}/>
                    </a>
                </div>

                <p>When a player crosses the boundary of an area, <strong>everything rendered in ThreeJS shifts in the
                    opposite direction</strong>.</p>
                <p>Here’s how it works step-by-step:</p>
                <ol>
                    <li>Suppose the player moves to the right and enters the next area.</li>
                    <li>The <strong>leftmost 3 areas</strong> are deleted along with their objects.</li>
                    <li>The player’s object and all other objects are shifted one area to the left. This effectively
                        resets the player’s position to the center of the 3D environment (0,0).
                    </li>
                    <li>New objects for the areas on the right are fetched from the server and rendered in the required
                        positions.
                    </li>
                    <li>The player is disconnected from old socket channels and connected to new ones.</li>
                </ol>
                <p>This method creates an illusion of movement while keeping the player’s position fixed, optimizing
                    rendering and server communication. The area transitions can be observed in the bottom-left corner
                    of the video provided.</p>

                <h2>Demo Video</h2>
                <p>
                    In the video, a user first logs in with the name "player" and moves across the map by left-clicking
                    with the mouse. Pressing the "C" key creates a new random object, which is both rendered on the map
                    and saved via the web server. Later, a second user logs in with the name "player2" from another
                    browser tab, follows the cubes created by the first user, and approaches their position. Area
                    transitions can be tracked from the platform information panel in the bottom-left corner.
                </p>

                <div className="text-center my-5">
                    <div className="ratio ratio-16x9">
                        <iframe
                            title="youtube"
                            className="embed-responsive-item"
                            src="https://www.youtube.com/embed/WJm3jf37Ze4?si=THLFp_bJqjq4FNAM"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>

                <h2>Future Plans</h2>
                <ul>
                    <li>Use a Google Maps-like API to synchronize the infinite map scale with a real-world map.</li>
                    <li>Develop an interface to allow players to create more detailed structures using cubes.</li>
                </ul>
            </ArticleLayout>
        </div>
    );
}

export default Construct;
