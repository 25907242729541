import Header from "../components/Header";
import Footer from "../components/Footer";

const ArticleLayout = ({ children }) => {
    return (
        <div>
            <Header />
            <div className="text-light bg-article p-5">
                <main>{children}</main>
            </div>
            <Footer />
        </div>
    );
};

export default ArticleLayout;