import TechCard from "../components/TechCard";
import Header from "../components/Header";
import Title from "../components/Title";
import Footer from "../components/Footer";

function Software() {
    const projects = [
        {
            img: '/img/construct/poster.png',
            title: 'Project Real World (The Construct) - 2024',
            description: 'Project Real World, also known as The Construct, is an MMORPG     platform that theoretically supports an infinite map size. The core aim of this project is to run and scale the entire platform using only JavaScript',
            link: 'software/construct',
            technologies: ['JavaScript', 'WebGL', 'ThreeJS', 'WebSocket', 'Redis', 'NodeJS', 'ExpressJS', 'MongoDB']
        },
        {
            img: '/img/commap/poster.png',
            title: 'Community Map - 2021',
            description: 'Community Map, a project of an Australian charity, is designed to connect those in need with volunteers using the Google MAPS API. Individuals can add their needs to the system by marking them on the map or simply entering an address. Volunteers can then browse these listings through the map or by searching keywords, and get in touch with those in need. In addition to a simple login mechanism, the system verifies the authenticity of users by using phone numbers.',
            technologies: ['Angular', 'NodeJS', 'ExpressJS', 'MongoDB', 'Google MAPS API']
        },
        {
            img: '/img/leprikon/poster.jpg',
            title: 'Leprikon - 2019',
            description: 'Leprikon is a web application designed to create and distribute cryptocurrency data sets. This was my university graduation project. The data sets help investors, governments, and banks make informed decisions about cryptocurrencies. Leprikon allows users to filter data for custom analysis, choosing from predefined filters or creating their own. The app constantly monitors new blockchain blocks and adds them to its database for better filtering. The collected data is presented in graphs, allowing users to easily view the information through a simple dashboard.',
            technologies: ['NodeJS', 'ExpressJS', 'MongoDB']
        }
    ];

    return (
        <div className="container">
            <Header />
            <Title
                title={'Software Projects'}
                description={'On this page, I list the projects I have developed as hobbies or freelance work in the fields of technology and software development. For the more recent projects, I have shared detailed information and screenshots. For the older projects, which I developed when I first started software development, there are fewer visuals and descriptions. However, all of these projects have repositories available. Access can be provided upon request.'}>
            </Title>

            {projects.map((project, index) => (
                <div className="mt-4" key={index}>
                    <TechCard
                        img={project.img}
                        title={project.title}
                        description={project.description}
                        link={project.link}
                        technologies={project.technologies}
                    />
                </div>
            ))}

            <Footer />
        </div>
    );
}

export default Software;
