import Header from "../components/Header";
import Footer from "../components/Footer";
import PhotoCarousel from "../components/PhotoCarousel";
import Title from "../components/Title";

const images = [
    { src: "/img/gallery/1.jpg" },
    { src: "/img/gallery/2.jpg" },
    { src: "/img/gallery/3.jpg" },
    { src: "/img/gallery/4.jpg" },
    { src: "/img/gallery/5.jpg" },
    { src: "/img/gallery/6.jpg" },
    { src: "/img/gallery/7.jpg" },
    { src: "/img/gallery/8.jpg" },
    { src: "/img/gallery/9.jpg" },
];

function Art() {
    return (
        <div className="container">
            <Header />
            <Title
                title="Art"
                description="On this page, I share the photos I've taken and my essays, including book and movie reviews as well as philosophical reflections. The articles have been translated from their original Turkish versions into English using artificial intelligence."
            ></Title>

            <PhotoCarousel images={images} />

            <Footer />
        </div>
    );
}

export default Art;
