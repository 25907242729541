import React from 'react';
import './styles/main.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import WhoAmI from "./pages/WhoAmI";
import Art from "./pages/Art";
import Software from "./pages/Software";
import Race from "./pages/Race";
import Construct from "./pages/software/Construct";

function App() {
  return (
      <Router>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/hck" element={<WhoAmI />} />
              <Route path="/art" element={<Art />} />
              <Route path="/software" element={<Software />} />
              <Route path="/race" element={<Race />} />

              <Route path="/software/construct" element={<Construct />} />
          </Routes>
      </Router>
  );
}

export default App;
