import React from 'react';

function Title({title, description}) {
    return (
        <div className="text-white my-5">
            <h1>{title}</h1>
            <div>
                {description}
            </div>
        </div>
    );
}

export default Title;
