import React from 'react';

function Footer() {
    return (
        <div className="my-5">

        </div>
    );
}

export default Footer;
