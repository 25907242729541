import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Home() {
    return (
        <div className="text-center container">
            <Header />

            <div className="mt-5">
                <Button as={Link} to={"/hck"} className="box-shadow-dark main-page-button" variant="outline-primary">Who am I?</Button>
            </div>
            <div className="mt-5">
                <Button as={Link} to={"/art"} className="box-shadow-dark main-page-button" variant="outline-primary">Art</Button>
                <Button as={Link} to={"/software"} className="box-shadow-dark main-page-button" variant="outline-primary">Software</Button>
                <Button as={Link} to={"/race"} className="box-shadow-dark main-page-button" variant="outline-primary">Racing</Button>
            </div>
            <div className="row" style={{ marginTop: '10rem' }}>
                <div className="col-4">
                    <a href="/Halis_Cagatay_Karakurt_CV_EN.pdf" download>
                        <img src='/img/icon_writing.png' alt="GIF Description" style={{width: '50px', height: 'auto'}}/>
                        <div className="text-primary">
                            Download CV
                        </div>
                    </a>
                </div>
                <div className="col-4">
                    <Link to={"https://www.linkedin.com/in/haliscagataykarakurt/"} target="_blank">
                        <img src='/img/icon_website.png' alt="GIF Description" style={{width: '50px', height: 'auto'}}/>
                        <div className="text-primary">
                            Linkedin
                        </div>
                    </Link>
                </div>
                <div className="col-4">
                    <a href={"mailto:hckarakurt@gmail.com"}>
                        <img src='/img/icon_envelope.png' alt="GIF Description"
                             style={{width: '50px', height: 'auto'}}/>
                        <div className="text-primary">
                            Email
                        </div>
                    </a>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default Home;
