import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import './TechCard.scss';
import { Link } from "react-router-dom";

function TechCard({ img, title, description, link, technologies }) {
    return (
        link ?
        <Link to={'/' + link}>
            <Card>
                <Card.Body>
                    <Row className="g-4">
                        <Col xs="12" md="6" lg="3">
                            <img src={img} alt={title} style={{width: '100%'}}/>
                        </Col>
                        <Col>
                            <Card.Title className={"text-primary"}>
                                {title}
                            </Card.Title>
                            <Card.Text className={"text-white"}>
                                {description}
                            </Card.Text>
                            <div className="mt-3">
                                {technologies.map((tech, index) => (
                                    <Badge bg={"danger"} key={index} className={"me-1"}>{tech}</Badge>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Link> :
        <Card>
            <Card.Body>
                <Row className="g-4">
                    <Col xs="12" md="6" lg="3">
                        <img src={img} alt={title} style={{width: '100%'}}/>
                    </Col>
                    <Col>
                        <Card.Title className={"text-primary"}>
                            {title}
                        </Card.Title>
                        <Card.Text className={"text-white"}>
                            {description}
                        </Card.Text>
                        <div className="mt-3">
                            {technologies.map((tech, index) => (
                                <Badge bg={"danger"} key={index} className={"me-1"}>{tech}</Badge>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default TechCard;
